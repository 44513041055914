import { Popover } from "@headlessui/react";
import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "@remix-run/react";
import { useState } from "react";

export type ColumnMenuLeaf = {
  label: string;
  to: string;
};
export type ColumnMenuItem = {
  label: string;
  items: ColumnMenuLeaf[];
};

export function ColumnMenu({ items }: { items: ColumnMenuItem[] }) {
  const [activeItem, setActiveItem] = useState<ColumnMenuItem | null>(null);
  return (
    <div className="flex flex-no-wrap bg-white h-80">
      <div className="p-4 max-w-lg border-r border-gray-100">
        {items.map(item => {
          return (
            <div
              key={item.label}
              className={`cursor-pointer group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 ${
                item == activeItem ? "bg-gray-100" : ""
              }`}
              onMouseOver={() => setActiveItem(item)}
            >
              <div className="flex-auto">
                <button className="font-semibold text-gray-900 flex items-center justify-between">
                  {item.label} <ChevronRightIcon className="h-4 w-5" />
                </button>
              </div>
            </div>
          );
        })}
        <div className="h-px bg-gray-200 my-2"></div>
        <div className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 flex-col space-y-3">
          <Popover.Button as={Link} to="/categories" className="text-sky-600">
            All Categories
          </Popover.Button>
          <Popover.Button as={Link} to="/vendors" className="text-sky-600">
            All Vendors
          </Popover.Button>
        </div>
      </div>

      {activeItem && (
        <div className="py-4 max-h-80 flex flex-col flex-wrap">
          {activeItem.items.map((item, i) => {
            return (
              <div
                key={item.label}
                className="border-r border-gray-100 group relative flex w-72 items-start gap-x-6 pr-4 "
              >
                {i < 9 ? (
                  <Popover.Button
                    as={Link}
                    to={item.to}
                    className="block font-normal text-gray-900 truncate hover:bg-gray-50 p-4 ml-4 rounded-lg"
                  >
                    {item.label}
                  </Popover.Button>
                ) : i === 9 ? (
                  <Popover.Button
                    as={Link}
                    to={`/categories`}
                    className="flex font-normal items-center truncate hover:bg-gray-50 p-4 ml-4 rounded-lg text-sky-600"
                  >
                    See all categories
                    <ArrowRightIcon className="h-4 w-4 ml-4" />
                  </Popover.Button>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
